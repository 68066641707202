<template>
  <div
    class="tab-pane fade col-md-12"
    :id="idDocumentUpload"
    role="tabpanel"
    :aria-labelledby="`${idDocumentUpload}-tab`"
  >
    <div class="container">
      <div class="row row-cols-2">
        <div class="col pl-0 mt-2 mt-4">
          <div class="input-group mb-2">
            <div class="custom-file inputFile">
              <input
                type="file"
                class="custom-file-input bg-light form-control"
                id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
                @change="
                  $emit('update:file', $event.target.files[0]),
                    $emit('update:fileName', $event.target.files[0].name)
                "
              />
              <label
                class="custom-file-label bgLabel d-flex align-items-center"
                for="inputGroupFile01"
                data-browse="Selecionar Arquivo"
                data-toggle="tooltip"
                data-placement="right"
              >
                {{ fileName }}
              </label>
            </div>
          </div>
          <label for="">Tamanho máximo de arquivo: 15mb.</label>
        </div>
        <div class="col mb-4">
          <label for="name"><strong>Título</strong> (Obrigatório)</label>
          <input
            class="form-control form-control-lg"
            id="name"
            type="text"
            placeholder="Nome"
            :value="name"
            @input="$emit('update:name', $event.target.value)"
          />
        </div>
      </div>
      <div class="row row-cols-1">
        <label for="Descrição" class="labelText">Descrição</label>
        <div class="col mb-12 p-0" id="textareaContainer">
          <textarea
            name="Descrição"
            id="Descrição"
            cols="2"
            rows="5"
            placeholder="Descrição"
            :value="description"
            @input="$emit('update:description', $event.target.value)"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["name", "file", "description", "id", "fileName", "idDocumentUpload"]
};
</script>
<style scoped>
* {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
}

.bgLabel::after {
  background-color: #f38235 !important;
  color: #fff !important;
  height: 39px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

input {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
  border: 1px solid #98c4e6;
}

.inputFile label {
  cursor: pointer;
  height: 40px;
}

label {
  font-weight: 400;
  color: #797984 !important;
}

textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #98c4e6;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
}

.labelText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
}
#textareaContainer {
  margin-bottom: 15px;
}
.borderRow {
  border-bottom: 1px solid #98c4e6;
}
</style>
